import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import { Link } from "gatsby"

function totDistance(edges) {
  let dist = 0
  edges.forEach(edge => {
    dist += edge.node.distance
    console.log(edge)
    return
  })
  return dist.toFixed(1)
}
const IndexPage = ({ data }) => (
  <Layout>
  <SEO title="Sunday Strolls" />
  <div className="min-h-screen bg-gray-900 p-2 md:p-8">
      <main>
          <div>
              <div className="relative">
                  <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-900"></div>
                  <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                      <div className="relative sm:rounded-2xl sm:overflow-hidden">
                          <div className="relative px-4 py-4 sm:px-6 sm:py-12 lg:py-12 lg:px-8">
                              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                  <span className="text-white">Sunday </span>
                                  <span className="text-yellow-500">Strolls</span>
                              </h1>
                              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-gray-200 sm:max-w-3xl">
                                  Weekly hikes during the pandemic
                              </p>
                              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                                  <dl className="mt-4 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-2 sm:gap-8">
                                      <div className="flex flex-col text-center">
                                          <dt className="order-2 mt-2 text-lg leading-6 font-medium text-white">
                                              Stroll Count
                                          </dt>
                                          <dd className="order-1 text-5xl font-extrabold text-yellow-500">
                                              {data.allContentfulStroll.edges.length}
                                          </dd>
                                      </div>
                                      <div className="flex flex-col">
                                          <dt className="order-2 mt-2 text-lg leading-6 font-medium text-white">
                                              Total Distance(km)
                                          </dt>
                                          <dd className="order-1 text-5xl font-extrabold text-yellow-500">
                                              {totDistance(data.allContentfulStroll.edges)}
                                          </dd>
                                      </div>
                                  </dl>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {data.allContentfulStroll.edges.map(({ node }) => {
                  const postLink = "/stroll/" + node.slug
                  return (
                      <Link to={postLink} key={node.slug}>
                          <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                              <div className="flex-shrink-0">
                                  <img className="h-48 w-full object-cover" src={node.featured.fluid.src} alt="" />
                              </div>
                              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                  <div className="flex-1">
                                      <p className="text-sm font-medium text-yellow-600">
                                          {node.datetime}
                                      </p>
                                      <div className="block mt-2">
                                          <p className="text-xl font-semibold text-gray-900">
                                              {node.title}
                                          </p>
                                          <p className="text-md text-gray-600">
                                              {node.city}
                                          </p>
                                      </div>
                                      <div className="flex-grow px-4 py-5 sm:p-6">
                                          <div className="flex items-center">
                                              <div className="flex-shrink-0 bg-yellow-500 rounded-md p-3">

                                                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                                                  </svg>
                                              </div>
                                              <div className="ml-5 w-0 flex-1">
                                                  <dt className="text-sm font-medium text-gray-500 truncate">
                                                      Distance (km)
                                                  </dt>
                                                  <dd className="flex items-baseline">
                                                      <div className="text-2xl font-semibold text-gray-900">
                                                          {node.distance}
                                                      </div>
                                                  </dd>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </Link>
                  )
              })}
          </div>
      </main>
  </div>
  </Layout>
)
export const query = graphql`

  query HomePageQuery {
    allContentfulStroll(sort: {order: DESC, fields: datetime}) {
      edges {
        node {
          id
          slug
          distance
          datetime(formatString: "MMMM Do, YYYY")
          featured {
            fluid {
              src
            }
          }
          title
          city
        }
      }
    }
  }
  `
export default IndexPage
